import React from 'react';
import {
  CodeSnippet,
  IconDataTableCode,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Icon } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../data/snippets/rn-icon.example');

const ReactNativeTab = ({ data }) => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Icon: Icon }}
        code={snippet}
        platform="react-native"
        gitHubLink="icon"
        layout="rn"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="name" types={['string']}>
            <Text>The name of the icon excluding spaces and dashes.</Text>
          </PropListItem>

          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the size of the icon.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="color" types={['oneOf', 'string']}>
            <Text>Determines the color of the icon.</Text>
            <List type="unordered">
              <li>
                <code>contrast</code>
              </li>
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>subtle</code>
              </li>
              <li>
                <code>nonessential</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="theme" types={['oneOf', 'string']}>
            <Text>Determines the color of the icon.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this icon in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the icon.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>Icons within buttons are automatically styled.</li>
          <li>FileType icons are set to large size by default.</li>
          <li>
            UI Control icons are set to small size by default. Certain UI
            Control icons may have different sizing which is indicated in the
            icon name.
          </li>
        </List>
      </Section>

      <Section title="Icons">
        <IconDataTableCode data={data} platform="web" hideOutlineIcons />
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
