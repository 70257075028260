import React from 'react';
import {
  CodeSnippet,
  IconDataTableCode,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Icon } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/icon.example');

const WebTab = ({ data }) => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Icon: Icon }}
        code={snippet}
        platform="react"
        gitHubLink="icon"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['string']}>
            <Text>The name of the icon excluding spaces and dashes.</Text>
          </PropListItem>

          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the size of the icon.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="color" types={['oneOf', 'string']}>
            <Text>Determines the color of the icon.</Text>
            <List type="unordered">
              <li>
                <code>contrast</code>
              </li>
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>subtle</code>
              </li>
              <li>
                <code>nonessential</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Allows you to pass in a class to style the icon’s fill or hover
              styles.
            </Text>
          </PropListItem>

          <PropListItem name="label" types={['string']}>
            <Text>
              Sets the text shown in the label when a user hovers over the icon.
              No label will be shown if this isn’t passed in.
            </Text>
          </PropListItem>

          <PropListItem name="labelPosition" types={['oneOf', 'string']}>
            <Text>
              Determines where the label will show up when a user hovers over
              the icon.
            </Text>
            <List type="unordered">
              <li>
                <code>top</code>
              </li>
              <li>
                <code>bottom</code> (default)
              </li>
              <li>
                <code>left</code>
              </li>
              <li>
                <code>right</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="labelParent" types={['string']}>
            <Text>
              If you're placing an icon in an absolutely positioned container,
              pass the id here to correctly position the icon label.
            </Text>
          </PropListItem>

          <PropListItem name="title" types={['string']}>
            <Text>
              Sets the <code>title</code> element on the icon’s SVG. By default,
              the name of the icon is the title. If the name of the icon doesn’t
              match its meaning in your interface, be sure to pass a title to
              aid those using screen readers.
            </Text>
          </PropListItem>

          <PropListItem name="showBrowserTooltip" types={['bool']}>
            <Text>
              Defaults to false. This determines whether or not to show the
              browser tooltip (which shows the icon’s title) when a user hovers
              over the icon.
            </Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this icon in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="children" types={['element']}>
            <Text>
              Allows passing a custom icon element into the Icon component.
            </Text>
            <List type="unordered">
              <li>
                Work with a designer to export an optimized path on a 32x32
                artboard.
              </li>
              <li>
                Pass only the <code>path</code> or <code>g</code> element, not
                the outer <code>svg</code>.
              </li>
            </List>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>Icons within buttons are automatically styled.</li>
          <li>FileType icons are set to large size by default.</li>
          <li>
            UI Control icons are set to small size by default. Certain UI
            Control icons may have different sizing which is indicated in the
            icon name.
          </li>
          <li>
            The fill color of all icons are provided by the CSS styling. The
            exception to this are the FileType icons which have custom fill
            colors.
          </li>
        </List>
      </Section>

      <Section title="Icons">
        <IconDataTableCode data={data} platform="web" hideOutlineIcons />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
