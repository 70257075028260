import React from 'react';
import { graphql } from 'gatsby';
import { PageHero, PageWithSubNav, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import pageHeroData from '../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="code" title="Icon" designCode subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Icon" />

      <PlatformTabs>
        <WebTab type="web" data={data} />
        <ReactNativeTab type="reactnative" data={data} />
        <AndroidTab type="android" data={data} />
        <AppleTab type="apple" data={data} />
        <OtherTab type="other" data={data} />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconCode {
    allIconsYaml(sort: { order: ASC, fields: [title] }) {
      edges {
        node {
          title
          icons {
            name
            react_name
            apple_name
            android_name
            rn_name
          }
        }
      }
    }
  }
`;
